import { useCallback } from 'react'

import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material'
import { isAfter } from 'date-fns'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { PlanCard } from 'app/lib/components/plans/PlanCard'
import { usePlans } from 'app/lib/hooks/use-plans'
import { fromISOdate, today, toISOdate } from 'app/lib/utils/date'
import { EligibilityDirective } from 'app/models/EligibilityRecord'
import { EligibilityRecord, OrganizationRoute } from 'app/models/scribe.models'
import { useSubmitEligibilityDirectiveMutation } from 'app/redux/scribeApi'

import { ActionType } from '../Profile'

import UpdateStatusDatePicker from './UpdateStatusDatePicker'

type Props = {
  actionType: ActionType
  eligibilityRecord: EligibilityRecord
  onClose: () => void
}

export type updateRecordStatusParams = Readonly<{
  activationDate: Date | null
  deactivationDate: Date | null
}>

const UpdateStatusDialog = ({ actionType, eligibilityRecord, onClose }: Props) => {
  const { t } = useTranslation()

  const {
    attributes: { uniqueIdentifier, firstName, lastName },
    eligibleIntervals,
  } = eligibilityRecord

  const isReactivation = actionType === ActionType.REACTIVATE

  const lastInterval = eligibleIntervals[eligibleIntervals.length - 1]
  const defaultActivationDate =
    (lastInterval?.startDate && fromISOdate(lastInterval.startDate)) || null
  const isFutureActivation = defaultActivationDate && isAfter(defaultActivationDate, today())

  const { organizationId } = useParams() as OrganizationRoute

  const { defaultPlan, shouldChangePlan } = usePlans(organizationId, eligibilityRecord)
  const shouldChangeCurrentPlan = isReactivation && defaultPlan && shouldChangePlan

  const [submitDirective] = useSubmitEligibilityDirectiveMutation({
    fixedCacheKey: 'eligibilityRecordUpdate',
  })

  const defaultValues = isReactivation
    ? { activationDate: today(), deactivationDate: null }
    : {
        activationDate: null,
        deactivationDate: isFutureActivation ? defaultActivationDate : today(),
      }

  const formMethods = useForm<updateRecordStatusParams>({ defaultValues })
  const { handleSubmit, reset } = formMethods

  const handleClose = useCallback(() => {
    reset()
    onClose()
  }, [reset, onClose])

  const updateRecordStatus = useCallback(
    ({ activationDate, deactivationDate }: updateRecordStatusParams) => {
      const body: EligibilityDirective = {
        uniqueIdentifier,
        activationDate: activationDate ? toISOdate(activationDate) : undefined,
        deactivationDate: deactivationDate ? toISOdate(deactivationDate) : '#',
      }
      if (shouldChangeCurrentPlan) {
        body.plan = defaultPlan?.attributes.label
      }
      return submitDirective({ organizationId, body }).unwrap().then(handleClose)
    },
    [
      organizationId,
      uniqueIdentifier,
      shouldChangeCurrentPlan,
      defaultPlan,
      submitDirective,
      handleClose,
    ],
  )

  const hasName = firstName && lastName

  return (
    <Dialog fullWidth maxWidth="md" open onClose={onClose} data-testid="update-status-dialog">
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(updateRecordStatus)} noValidate>
          <DialogContent sx={{ padding: 4 }}>
            <Stack spacing={4}>
              <Typography variant="h3">
                {hasName
                  ? t(`eligibilityRecordPage.dialog.${actionType}.title`, { firstName, lastName })
                  : t(`eligibilityRecordPage.dialog.${actionType}.titleNoName`)}
              </Typography>
              <Stack spacing={3}>
                <Typography variant="body2">
                  {hasName
                    ? t(`eligibilityRecordPage.dialog.${actionType}.areYouSure`, {
                        firstName,
                        lastName,
                      })
                    : t(`eligibilityRecordPage.dialog.${actionType}.areYouSureNoName`)}
                </Typography>
                <UpdateStatusDatePicker
                  actionType={actionType}
                  defaultActivationDate={defaultActivationDate}
                />
              </Stack>
              {shouldChangeCurrentPlan && (
                <Stack spacing={2}>
                  <Typography variant="body2">
                    {t(`eligibilityRecordPage.dialog.reactivate.assignedDefaultPlan`)}
                  </Typography>
                  <PlanCard plan={defaultPlan} />
                </Stack>
              )}
              {isReactivation && (
                <Typography variant="body2">
                  {t(`eligibilityRecordPage.dialog.reactivate.sendNewMembersAnInviteNotAllowed`)}
                </Typography>
              )}
            </Stack>
          </DialogContent>
          <DialogActions sx={{ paddingX: 4, paddingBottom: 4, paddingTop: 0 }}>
            <Button variant="outlined" onClick={handleClose}>
              {t('global.dialog.cancel')}
            </Button>
            <Button variant="contained" type="submit" key="preventDoubleSubmit">
              {t(`global.dialog.${actionType}`)}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}

export default UpdateStatusDialog
