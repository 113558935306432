import { useCallback } from 'react'

import { DatePicker } from '@mui/x-date-pickers'
import { isAfter, isBefore, isSameDay, startOfDay } from 'date-fns'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { isValidDate, today, yesterday } from 'app/lib/utils/date'

import { ActionType } from '../Profile'

type Props = {
  actionType: ActionType
  defaultActivationDate: Date | null
}

const UpdateStatusDatePicker = ({ actionType, defaultActivationDate }: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const isReactivation = actionType === ActionType.REACTIVATE
  const isFutureActivation = defaultActivationDate && isAfter(defaultActivationDate, today())

  const shouldDisableDate = useCallback(
    (date: Date) => {
      const thisDay = startOfDay(today())
      const previousDay = startOfDay(yesterday())
      if (isReactivation) {
        return isBefore(date, thisDay)
      }
      if (isFutureActivation) {
        return (
          isBefore(date, defaultActivationDate) &&
          !isSameDay(date, defaultActivationDate) &&
          !isSameDay(date, previousDay)
        )
      }
      return isBefore(date, previousDay)
    },
    [defaultActivationDate, isFutureActivation, isReactivation],
  )

  const requiredRule: RegisterOptions = {
    required: t('global.isRequired', '', {
      label: t(`eligibilityRecordPage.dialog.${actionType}.date.label`),
    }) as string,
  }

  const validationRule: RegisterOptions = {
    validate: (val: Date) => {
      if (val && !isValidDate(val)) {
        return t('global.invalidDate') as string
      }

      if (shouldDisableDate(val)) {
        return t('global.notAllowedDate') as string
      }

      return true
    },
  }
  return (
    <Controller
      name={isReactivation ? 'activationDate' : 'deactivationDate'}
      control={control}
      rules={isReactivation ? { ...requiredRule, ...validationRule } : validationRule}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          label={t(`eligibilityRecordPage.dialog.${actionType}.date.label`)}
          format="yyyy-MM-dd"
          openTo="day"
          views={['year', 'day']}
          shouldDisableDate={shouldDisableDate}
          slotProps={{
            textField: {
              name: 'date',
              error: !!error,
              helperText: error?.message,
              required: isReactivation,
              variant: 'outlined',
              sx: {
                maxWidth: '320px',
              },
            },
          }}
        />
      )}
    />
  )
}

export default UpdateStatusDatePicker
