import { useMemo } from 'react'

import {
  getCurrentAndOtherPlans,
  getDefaultAndOtherPlans,
  isArchived,
} from 'app/models/Organization'
import { EligibilityRecord } from 'app/models/scribe.models'
import { useLegacyGetOrganizationPlansQuery } from 'app/redux/scribeApi'

export function usePlans(organizationId: string, eligibilityRecord: EligibilityRecord) {
  const { data: plans } = useLegacyGetOrganizationPlansQuery(organizationId)

  const [currentPlan, otherThanCurrent] = useMemo(
    () => getCurrentAndOtherPlans(plans, eligibilityRecord),
    [plans, eligibilityRecord],
  )
  const [defaultPlan, otherThanDefault] = useMemo(() => getDefaultAndOtherPlans(plans), [plans])

  const shouldChangePlan = Boolean(currentPlan && isArchived(currentPlan) && defaultPlan)

  return { currentPlan, defaultPlan, otherThanCurrent, otherThanDefault, shouldChangePlan }
}
